export default {
    methods: {

      // Abre el modal pasando el ID
    showTransaction(id) {
      this.dialog = true;
      this.transactions.points_records.id = id;
    },
        // Mostrar y ocultar transacciones
    // TODO: limpiar
    showAllTransactions() {
        document.getElementById("all-transactions").classList.add("active");
        document
          .getElementById("subtraction-transactions")
          .classList.remove("active");
        document
          .getElementById("addition-transactions")
          .classList.remove("active");
        this.allTransactions = true;
        this.additionTransactions = false;
        this.subtractionTransactions = false;
      },
  
      showSubtractionTransactions() {
        document
          .getElementById("subtraction-transactions")
          .classList.add("active");
        document.getElementById("all-transactions").classList.remove("active");
        document
          .getElementById("addition-transactions")
          .classList.remove("active");
        this.allTransactions = false;
        this.additionTransactions = false;
        this.subtractionTransactions = true;
      },
  
      showAdditionTransactions() {
        document.getElementById("addition-transactions").classList.add("active");
        document
          .getElementById("subtraction-transactions")
          .classList.remove("active");
        document.getElementById("all-transactions").classList.remove("active");
        this.allTransactions = false;
        this.additionTransactions = true;
        this.subtractionTransactions = false;
      },
    }
}